import errorHandler from "../helpers/errorHandler";
import render from '../helpers/renderRouterView'

export default [
  {
    path: 'notice/',
    component: {
      render
    },
    children: [
      {
        path: '',
        name: 'TheNotification',
        component: () => import('../views/TheNotification/TheNotification').catch(errorHandler),
        meta: {
          authorization: true,
          title: 'Уведомление',
        },
      },
    ],
  },
]
