<template>
  <div class="loader-wrapper" :class="{show: spinnerLoader}">
    <div class="loader"></div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: "AppLoader",

  computed: {
    ...mapGetters([
      'spinnerLoader'
    ])
  }
}
</script>

<style scoped>
.loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9999;
  display: none;
  align-items: center;
  justify-content: center;
  background: rgba(245, 245, 245, 0.7);
  backdrop-filter: blur(3px);
}

.loader-wrapper.show {
  display: flex;
}

.loader {
  border: 3px solid #f3f3f3;
  border-radius: 50%;
  border-top: 3px solid #2787F5;
  width: 40px;
  height: 40px;
  animation: spin 0.4s linear infinite;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
