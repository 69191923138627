const state = {
  spinnerLoader: false,
}

const mutations = {
  setSpinnerLoader(state, payload) {
    state.spinnerLoader = payload;
  },
}

const getters = {
  spinnerLoader: (state) => state.spinnerLoader,
}

export default {
  state,
  mutations,
  getters,
}
