import errorHandler from "../helpers/errorHandler";
import render from '../helpers/renderRouterView'

export default [
  {
    path: 'profile/',
    component: {
      render
    },
    children: [
      {
        path: '',
        name: 'TheProfile',
        component: () => import('../views/TheProfile/TheProfile').catch(errorHandler),
        meta: {
          authorization: true,
          title: 'Профиль',
        },
      },
      {
        path: 'edit',
        name: 'TheProfileEdit',
        component: () => import('../views/TheProfile/TheProfileEdit').catch(errorHandler),
        meta: {
          authorization: true,
          title: 'Редактировать профиль',
        },
      },
      {
        path: 'password',
        name: 'TheProfilePassword',
        component: () => import('../views/TheProfile/TheProfilePassword').catch(errorHandler),
        meta: {
          authorization: true,
          title: 'Изменить пароль',
        },
      },
    ],
  },
]
