import Vue from 'vue';
import Vuex from 'vuex';
import auth from "./modules/auth";
import common from "./modules/common";
// import notification from "./modules/notification";
// import loader from "./modules/loader";
// import translater from "./modules/translater";
// import navigation from "./modules/navigation";
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    common
    // auth,
    // notification,
    // loader,
    // translater,
    // navigation
  }
})
