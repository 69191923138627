import instance  from "@/api/instance";
const END_POINT = '/api';

const order = {
  getOrders: (data) => instance.get(`${END_POINT}/orders`, data),
  getOrder: (data) => instance.get(`${END_POINT}/order`, data),
  getMyOrders: () => instance.get(`${END_POINT}/user-order`),
  addMyOrder: (data) => instance.post(`${END_POINT}/order-register`, data),
  updateMyOrder: (data) => instance.post(`${END_POINT}/order-update`, data),
  removeMyOrder: (data) => instance.post(`${END_POINT}/remove-order`, data),
}

export default {
  order
}
