import main from './main.api';
import search from './search.api';
import filter from './filter.api';
import vehicle from "./vehicle.api";
import service from "./service.api";
import shop from "./shop.api";
import repair from "./repair.api";
import operator from "./operator.api";
import order from "./order.api";
import auth from "./auth.api";
import social from "./social.api";
import profile from "./profile.api";
import favourite from "./favourite.api";
import notification from "./notification.api";

const API = {
    auth,
    ...main,
    ...search,
    ...filter,
    ...vehicle,
    ...service,
    ...shop,
    ...repair,
    ...operator,
    ...order,
    ...social,
    ...profile,
    ...notification,
    favourite,
}

export default API
