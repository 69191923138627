import errorHandler from "../helpers/errorHandler";
import render from '../helpers/renderRouterView'

export default [
  {
    path: 'repair/',
    component: {
      render
    },
    children: [
      {
        path: '',
        name: 'TheRepairList',
        component: () => import('../views/TheRepair/TheRepairList').catch(errorHandler),
        meta: {
          authorization: false,
          title: 'Ремонт',
        },
      },
      {
        path: 'my',
        name: 'TheMyRepair',
        component: () => import('../views/TheRepair/TheMyRepair').catch(errorHandler),
        meta: {
          authorization: false,
          title: 'Ремонт',
        },
      },
      {
        path: 'favourite',
        name: 'TheFavouriteRepair',
        component: () => import('../views/TheRepair/TheFavouriteRepair').catch(errorHandler),
        meta: {
          authorization: false,
          title: 'Ремонт',
        },
      },
    ],
  },
  {
    path: 'repair/:id',
    name: 'TheRepairDetail',
    component: () => import('../views/TheRepair/TheRepairDetail').catch(errorHandler),
    meta: {
      authorization: false,
      title: 'Ремонт',
    },
  },
]
