import errorHandler from "../helpers/errorHandler";

export default [
  {
    path: '/authorize',
    name: 'TheAuthorize',
    component: () => import('../views/TheAuth/TheAuthorize').catch(errorHandler),
  },
  {
    path: '/login',
    name: 'TheLogin',
    component: () => import('../views/TheAuth/TheLogin').catch(errorHandler),
  },
  {
    path: '/registration',
    name: 'TheRegistration',
    component: () => import('../views/TheAuth/TheRegistration').catch(errorHandler),
  },
  {
    path: '/reset-pwd',
    name: 'TheResetPwd',
    component: () => import('../views/TheAuth/TheResetPwd').catch(errorHandler),
  },
]
