import Vue from 'vue'
import VueRouter from 'vue-router'
import render from '../helpers/renderRouterView'
import store from '../store';
import search from "@/router/search";
import vehicle from "@/router/vehicle";
import service from "@/router/service";
import shop from "@/router/shop";
import repair from "@/router/repair";
import operator from "@/router/operator";
import order from "@/router/order";
import authorize from "@/router/authorize";
import create from "@/router/create";
import favourite from "@/router/favourite";
import profile from "@/router/profile";
import notification from "@/router/notification";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: {
      render
    },
    children: [
      {
        path: '',
        name: 'TheHome',
        component: () => import('../views/TheHome'),
        meta: {
          title:'Мой профиль и достижения',
        },
      },
      ...search,
      ...vehicle,
      ...service,
      ...shop,
      ...repair,
      ...operator,
      ...order,
      ...authorize,
      ...create,
      ...favourite,
      ...profile,
      ...notification,
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  routes,
})

// router.beforeEach((to, from, next) => {
//
//   if (to.path !== '/login' && !store.getters['loggedIn']) next('/login')
//
//
//   return next();
//
// })

router.beforeEach((to, from, next) => {
  if(to.name === 'TheProfile' && !store.getters.loggedIn) return next('/authorize')
  return next()
})

router.afterEach(async (to) => {
  if (!['VAuth', 'VEmailConfirm'].includes(to.name)) {
    Vue.nextTick(() => {
      // document.getElementById('pagetitle').innerHTML =  to.meta.title;
    });
  }
});

export default router
