import errorHandler from "../helpers/errorHandler";
import render from '../helpers/renderRouterView'

export default [
  {
    path: 'service/',
    component: {
      render
    },
    children: [
      {
        path: '',
        name: 'TheServiceList',
        component: () => import('../views/TheService/TheServiceList').catch(errorHandler),
        meta: {
          authorization: false,
          title: 'Услуги',
        },
      },
      {
        path: 'my',
        name: 'TheMyService',
        component: () => import('../views/TheService/TheMyService').catch(errorHandler),
        meta: {
          authorization: false,
          title: 'Услуги',
        },
      },
      {
        path: 'favourite',
        name: 'TheFavouriteService',
        component: () => import('../views/TheService/TheFavouriteService').catch(errorHandler),
        meta: {
          authorization: false,
          title: 'Услуги',
        },
      },
    ],
  },
  {
    path: 'service/:id',
    name: 'TheServiceDetail',
    component: () => import('../views/TheService/TheServiceDetail').catch(errorHandler),
    meta: {
      authorization: false,
      title: 'Услуги',
    },
  },
]
