import errorHandler from "../helpers/errorHandler";
import render from '../helpers/renderRouterView'

export default [
  {
    path: 'vehicles/',
    component: {
      render
    },
    children: [
      {
        path: '',
        name: 'TheVehicleList',
        component: () => import('../views/TheVehicle/TheVehicleList').catch(errorHandler),
        meta: {
          authorization: false,
          title: 'Спецтехника',
        },
      },
      {
        path: 'my',
        name: 'TheMyVehicle',
        component: () => import('../views/TheVehicle/TheMyVehicle').catch(errorHandler),
        meta: {
          authorization: false,
          title: 'Спецтехника',
        },
      },
      {
        path: 'favourite',
        name: 'TheFavouriteVehicle',
        component: () => import('../views/TheVehicle/TheFavouriteVehicle').catch(errorHandler),
        meta: {
          authorization: false,
          title: 'Спецтехника',
        },
      },
    ],
  },
  {
    path: 'vehicle/:id',
    name: 'TheVehicleDetail',
    component: () => import('../views/TheVehicle/TheVehicleDetail').catch(errorHandler),
    meta: {
      authorization: false,
      title: 'Спецтехника',
    },
  },
]
