import errorHandler from "../helpers/errorHandler";

export default [
  {
    path: 'search',
    name: 'TheSearch',
    component: () => import('../views/TheSearch').catch(errorHandler),
    meta: {
      authorization: false,
      title:'Поиск',
    },
  }
]
