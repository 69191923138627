import instance  from "@/api/instance";
const END_POINT = '/api';

const operator = {
  getOperators: (data) => instance.get(`${END_POINT}/driver-applications`, data),
  getOperator: (data) => instance.get(`${END_POINT}/driver-application`, data),
  getMyOperators: () => instance.get(`${END_POINT}/user-driver-applications`),
  addMyOperator: (data) => instance.post(`${END_POINT}/driver-application`, data),
  updateMyOperator: (data) => instance.post(`${END_POINT}/driver-application-update`, data),
  removeMyOperator: (id) => instance.delete(`${END_POINT}/driver-application/${id}`),
}

export default {
  operator
}
