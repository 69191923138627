<template>
  <div id="app">
    <router-view />
    <app-loader />
    <notifications
        position="bottom left"
        width="100%"
        classes="app-notification"
        :duration="2000"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AppLoader from "@/components/AppLoader";

export default {
  name: 'App',

  components: {
    AppLoader
  },

  computed: {
    ...mapGetters([
    ]),
  },
}

</script>

<style lang="scss">

</style>
