import instance  from "@/api/instance";
const END_POINT = '/api';

const filter = {
    getMarks: () => instance.get(`${END_POINT}/marks`),
    getRegions: () => instance.get(`${END_POINT}/areas?region_only=1`),
    getAreas: () => instance.get(`${END_POINT}/areas`),
    getVehicles: () => instance.get(`${END_POINT}/vehicle-types`),
    getProfessions: () => instance.get(`${END_POINT}/professions`),
    getShopCategories: () => instance.get(`${END_POINT}/categories`),
    getCountries: () => instance.get(`${END_POINT}/countries`),
    getOrderTypes: () => instance.get(`${END_POINT}/order-types`),
    getFuels: () => instance.get(`${END_POINT}/fuel-types`),
    getStatuses: () => instance.get(`${END_POINT}/vehicle-statuses`),
}

export default {
    filter
}
