import instance  from "@/api/instance";
const END_POINT = '/api';

const shop = {
  getShopItems: (data) => instance.get(`${END_POINT}/shop-items`, data),
  getShopItem: (data) => instance.get(`${END_POINT}/shop-item`, data),
  getMyShopItems: () => instance.get(`${END_POINT}/my-shop-items`),
  removeMyShopItem: (data) => instance.post(`${END_POINT}/delete-shop-item`, data),
  addMyShopItem: (data) => instance.post(`${END_POINT}/shop-item-register`, data),
  updateMyShopItem: (data) => instance.post(`${END_POINT}/shop-item-update`, data),
  checkMyShop: () => instance.get(`${END_POINT}/check-shop`),
  addMyShop: (data) => instance.post(`${END_POINT}/shop-register`, data),
}

export default {
  shop
}
