import errorHandler from "../helpers/errorHandler";
import render from '../helpers/renderRouterView'

export default [
  {
    path: 'order/',
    component: {
      render
    },
    children: [
      {
        path: '',
        name: 'TheOrderList',
        component: () => import('../views/TheOrder/TheOrderList').catch(errorHandler),
        meta: {
          authorization: false,
          title: 'Заказы',
        },
      },
      {
        path: 'my',
        name: 'TheMyOrder',
        component: () => import('../views/TheOrder/TheMyOrder').catch(errorHandler),
        meta: {
          authorization: false,
          title: 'Заказы',
        },
      },
      {
        path: 'favourite',
        name: 'TheFavouriteOrder',
        component: () => import('../views/TheOrder/TheFavouriteOrder').catch(errorHandler),
        meta: {
          authorization: false,
          title: 'Заказы',
        },
      },
    ],
  },
  {
    path: 'order/:id',
    name: 'TheOrderDetail',
    component: () => import('../views/TheOrder/TheOrderDetail').catch(errorHandler),
    meta: {
      authorization: false,
      title: 'Заказы',
    },
  },
]
