import errorHandler from "../helpers/errorHandler";
import render from '../helpers/renderRouterView'
import store from '@/store';
import API from '@/api';
const ifAuthenticated = async (to, from, next) => {
  if (store.getters.loggedIn) next()
  else {
    next('/');
  }
}

export default [
  {
    path: 'create/',
    component: {
      render
    },
    children: [
      {
        path: '',
        name: 'TheCreateList',
        component: () => import('../views/TheCreate/TheCreateList').catch(errorHandler),
        meta: {
          authorization: true,
          title: 'Создать объявление',
        },
      },
      {
        path: 'vehicle',
        name: 'TheCreateVehicle',
        component: () => import('../views/TheCreate/TheCreateVehicle').catch(errorHandler),
        meta: {
          authorization: true,
          title: 'Создать спецтехнику',
        },
        beforeEnter: ifAuthenticated
      },
      {
        path: 'order',
        name: 'TheCreateOrder',
        component: () => import('../views/TheCreate/TheCreateOrder').catch(errorHandler),
        meta: {
          authorization: true,
          title: 'Создать заказ',
        },
        beforeEnter: ifAuthenticated
      },
      {
        path: 'service',
        name: 'TheCreateService',
        component: () => import('../views/TheCreate/TheCreateService').catch(errorHandler),
        meta: {
          authorization: true,
          title: 'Создать услугу',
        },
        beforeEnter: ifAuthenticated
      },
      {
        path: 'shop',
        name: 'TheCreateShop',
        component: () => import('../views/TheCreate/TheShop/TheCreateShop').catch(errorHandler),
        meta: {
          authorization: true,
          title: 'Создать магазин',
        },
        beforeEnter: (to, from, next) => {
          ifAuthenticated
          API.shop.checkMyShop().then((res) => {
            if (res.status.id === 2) next('/create/shop-item');
            else if (res.status.id === 1) next('/create/shop-status');
            else if (res.status.id === 3) next('/create/shop-status?reject="true"');
            else next()
          });
        },
      },
      {
        path: 'shop-status',
        name: 'TheShopStatus',
        component: () => import('../views/TheCreate/TheShop/TheShopStatus').catch(errorHandler),
        meta: {
          authorization: true,
          title: 'Создать запчасть',
        },
        beforeEnter: ifAuthenticated
      },
      {
        path: 'shop-item',
        name: 'TheCreateShopItem',
        component: () => import('../views/TheCreate/TheShop/TheCreateShopItem').catch(errorHandler),
        meta: {
          authorization: true,
          title: 'Создать запчасть',
        },
        beforeEnter: ifAuthenticated
      },
      {
        path: 'repair',
        name: 'TheCreateRepair',
        component: () => import('../views/TheCreate/TheCreateRepair').catch(errorHandler),
        meta: {
          authorization: true,
          title: 'Создать ремонт',
        },
        beforeEnter: ifAuthenticated
      },
      {
        path: 'operator',
        name: 'TheCreateOperator',
        component: () => import('../views/TheCreate/TheOperator/TheCreateOperator').catch(errorHandler),
        meta: {
          authorization: true,
          title: 'Создать вакансию',
        },
        beforeEnter: (to, from, next) => {
          ifAuthenticated;
          API.service.getMyServices().then((res) => {
            if (!res.vehicles.length) next('/create/operator-status');
            else next()
          });
        }
      },
      {
        path: 'operator-status',
        name: 'TheOperatorStatus',
        component: () => import('../views/TheCreate/TheOperator/TheOperatorStatus').catch(errorHandler),
        meta: {
          authorization: true,
          title: 'Создать вакансию',
        },
        beforeEnter: ifAuthenticated
      },
    ],
  },
]
