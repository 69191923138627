import errorHandler from "../helpers/errorHandler";
import render from '../helpers/renderRouterView'

export default [
  {
    path: 'operator/',
    component: {
      render
    },
    children: [
      {
        path: '',
        name: 'TheOperatorList',
        component: () => import('../views/TheOperator/TheOperatorList').catch(errorHandler),
        meta: {
          authorization: false,
          title: 'Оператор',
        },
      },
      {
        path: 'my',
        name: 'TheMyOperator',
        component: () => import('../views/TheOperator/TheMyOperator').catch(errorHandler),
        meta: {
          authorization: false,
          title: 'Оператор',
        },
      },
      {
        path: 'favourite',
        name: 'TheFavouriteOperator',
        component: () => import('../views/TheOperator/TheFavouriteOperator').catch(errorHandler),
        meta: {
          authorization: false,
          title: 'Оператор',
        },
      },
    ],
  },
  {
    path: 'operator/:id',
    name: 'TheOperatorDetail',
    component: () => import('../views/TheOperator/TheOperatorDetail').catch(errorHandler),
    meta: {
      authorization: false,
      title: 'Оператор',
    },
  },
]
