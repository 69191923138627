import instance  from "@/api/instance";
const END_POINT = '/api';

const service = {
  getServiceList: (data) => instance.get(`${END_POINT}/vehicles`, data),
  getService: (data) => instance.get(`${END_POINT}/user-vehicle`, data),
  getMyServices: () => instance.get(`${END_POINT}/user-vehicles`),
  addMyService: (data) => instance.post(`${END_POINT}/vehicle-register`, data),
  updateMyService: (data) => instance.post(`${END_POINT}/vehicle-update`, data),
  removeMyService: (data) => instance.post(`${END_POINT}/delete-vehicle`, data),
}


export default {
  service
}
