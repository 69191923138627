import errorHandler from "../helpers/errorHandler";
import render from '../helpers/renderRouterView'
import store from '@/store';
const ifAuthenticated = async (to, from, next) => {
  if (store.getters.loggedIn) next()
  else {
    next('/');
  }
}

export default [
  {
    path: 'favourite/',
    component: {
      render
    },
    children: [
      {
        path: '',
        name: 'TheFavouriteList',
        component: () => import('../views/TheFavourite/TheFavouriteList').catch(errorHandler),
        meta: {
          authorization: true,
          title: 'Избранное',
        },
      },
      {
        path: 'vehicle',
        name: 'TheVehicleFavourite',
        component: () => import('../views/TheFavourite/TheFavouriteVehicle').catch(errorHandler),
        meta: {
          authorization: true,
          title: 'Избранное',
        },
        beforeEnter: ifAuthenticated
      },
      {
        path: 'order',
        name: 'TheOrderFavourite',
        component: () => import('../views/TheFavourite/TheFavouriteOrder').catch(errorHandler),
        meta: {
          authorization: true,
          title: 'Избранное',
        },
        beforeEnter: ifAuthenticated
      },
      {
        path: 'service',
        name: 'TheServiceFavourite',
        component: () => import('../views/TheFavourite/TheFavouriteService').catch(errorHandler),
        meta: {
          authorization: true,
          title: 'Избранное',
        },
        beforeEnter: ifAuthenticated
      },
      {
        path: 'shop',
        name: 'TheShopFavourite',
        component: () => import('../views/TheFavourite/TheFavouriteShop').catch(errorHandler),
        meta: {
          authorization: true,
          title: 'Избранное',
        },
        beforeEnter: ifAuthenticated
      },
      {
        path: 'repair',
        name: 'TheRepairFavourite',
        component: () => import('../views/TheFavourite/TheFavouriteRepair').catch(errorHandler),
        meta: {
          authorization: true,
          title: 'Избранное',
        },
        beforeEnter: ifAuthenticated
      },
      {
        path: 'operator',
        name: 'TheOperatorFavourite',
        component: () => import('../views/TheFavourite/TheFavouriteOperator').catch(errorHandler),
        meta: {
          authorization: true,
          title: 'Избранное',
        },
        beforeEnter: ifAuthenticated
      },
    ],
  },
]
