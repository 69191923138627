import instance  from "@/api/instance";
const END_POINT = '/api';

const vehicle = {
  getVehicles: (data) => instance.get(`${END_POINT}/sale-vehicles`, data),
  getVehicle: (data) => instance.get(`${END_POINT}/sale-vehicle`, data),
  getMyVehicles: () => instance.get(`${END_POINT}/my-sale-vehicles`),
  addMyVehicle: (data) => instance.post(`${END_POINT}/sale-vehicle-register`, data),
  updateMyVehicle: (data) => instance.post(`${END_POINT}/sale-vehicle-update`, data),
  removeMyVehicle: (data) => instance.post(`${END_POINT}/delete-sale-vehicles`, data),
}

export default {
  vehicle
}
