import instance  from "@/api/instance";
const END_POINT = '/api';

const profile = {
  getProfile: () => instance.get(`${END_POINT}/user`),
  updateProfile: (data) => instance.post(`${END_POINT}/user-update`,data),
  updatePwd: (data) => instance.post(`${END_POINT}/password-update`,data),
}


export default {
  profile
}
