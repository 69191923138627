import instance  from "@/api/instance";
const END_POINT = '/api';

const repair = {
  getRepairs: (data) => instance.get(`${END_POINT}/repairmens`, data),
  getRepair: (data) => instance.get(`${END_POINT}/repairmen`, data),
  getMyRepairs: () => instance.get(`${END_POINT}/my-repairmen`),
  addMyRepair: (data) => instance.post(`${END_POINT}/repairmen-register`, data),
  removeMyRepair: (data) => instance.post(`${END_POINT}/delete-repairmen`, data),
}

export default {
  repair
}
