import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import 'vue-select/dist/vue-select.css';
import '@/assets/style/index.scss';
import init from '@/configuration/init';
import VueMask from 'v-mask';
import vClickOutside from 'v-click-outside';
import Notifications from 'vue-notification'
import { i18n } from './plugins/i18n';

Vue.config.productionTip = false;
Vue.use(require('vue-moment'));
Vue.use(VueMask);
Vue.use(vClickOutside);
Vue.use(Notifications)

init(store, () => {
  new Vue({
    router,
    i18n,
    store,
    render: h => h(App),
  }).$mount('#app')
});

